import { initializeApp } from 'firebase/app'
import { getAuth, signInWithEmailAndPassword, browserLocalPersistence, browserSessionPersistence, setPersistence } from 'firebase/auth'
import { getFirestore } from 'firebase/firestore'

const firebaseConfig = {
    apiKey: "AIzaSyBwnuQ0PS6R2fvSdIQ9qSpz97m3Q4Lia-4",
    authDomain: "lexcraft-a4db7.firebaseapp.com",
    databaseURL: "https://lexcraft-a4db7-default-rtdb.europe-west1.firebasedatabase.app",
    projectId: "lexcraft-a4db7",
    storageBucket: "lexcraft-a4db7.appspot.com",
    messagingSenderId: "848696977948",
    appId: "1:848696977948:web:6c66fc6762aec93ac80dd9",
    measurementId: "G-FWFRZEQ0J7"
};
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const db = getFirestore();

auth.onAuthStateChanged(function(user) {
    if (user) {
        console.log('user is signed in')
    } else {
        console.log('user logged out')
    }
});

/*
const googleProvider = new firebase.auth.GoogleAuthProvider();
const signInWithGoogle = async () => {
    try {
        const res = await auth.signInWithPopup(googleProvider);
        const user = res.user;
        const query = await db
        .collection("users")
        .where("uid", "==", user.uid)
        .get();
        if (query.docs.length === 0) {
            await db.collection("users").add({
                uid: user.uid,
                name: user.displayName,
                authProvider: "google",
                email: user.email,
            });
        }
    } catch (err) {
        console.error(err);
        alert(err.message);
    }
};
*/
const login = async (email, password, remember) => {
    if (remember === false) {
        setPersistence(auth, browserSessionPersistence).then(() => {return signInWithEmailAndPassword(auth, email, password)})
    } else if (remember === true) {
        setPersistence(auth, browserLocalPersistence).then(() => {return signInWithEmailAndPassword(auth, email, password)})
    }
    
    /*
    .catch((error) => {
        // Handle Errors here.
        // const errorCode = error.code;
        // const errorMessage = error.message;
        console.log(error)
    });
    */
};
const registerWithEmailAndPassword = async (name, email, password) => {
    try {
        const res = await auth.createUserWithEmailAndPassword(email, password);
        const user = res.user;
        await db.collection("users").add({
        uid: user.uid,
        name,
        authProvider: "local",
        email,
    });
    } catch (err) {
        console.error(err);
        alert(err.message);
    }
};
const sendPasswordResetEmail = async (email) => {
    try {
        await auth.sendPasswordResetEmail(email);
        alert("Password reset link sent!");
    } catch (err) {
        console.error(err);
        alert(err.message);
    }
};
const logout = () => {
    auth.signOut();
};
export {
    auth,
    db,
    //signInWithGoogle,
    login,
    registerWithEmailAndPassword,
    sendPasswordResetEmail,
    logout,
};