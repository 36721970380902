import React, { useState } from 'react'
import { Contact, Main, Login, User, Game, Privacy, Footer, ComingSoon } from './Pages/index'
import { BrowserRouter as Router, Switch, Route} from "react-router-dom"
import ReactNotification from 'react-notifications-component'
import 'react-notifications-component/dist/theme.css'
import NavComp from './Components/Nav'

function App() {
    const [username, setUsername] = useState("Login")

    return (
        <Router>
            <div>

                {/* <ReactNotification /> */}
                {/* <NavComp username={username} setUsername={setUsername}/> */}
                <Switch>
                    <Route exact path="/" render={ComingSoon}/>
                    <Route path="/contact" render={Contact}/>
                    <Route path="/privacy" render={Privacy}/>
                    {/* <Route exact path="/g/:id"> */}
                    {/*     <Game/> */}
                    {/* </Route> */}
                    {/* <Route path="/u/:id"> */}
                    {/*     <User username={username}/> */}
                    {/* </Route> */}
                    {/* <Route path="/login"> */}
                    {/*     <Login setUsername={setUsername} username={username}/> */}
                    {/* </Route> */}
                </Switch>
                <div style={{height: "56px"}} />
                <Footer/>
            </div>
        </Router>
    )
}

export default App
