import React from "react";
import './footer.css';
import { Link } from 'react-router-dom';
import { Navbar } from 'react-bootstrap';

export default function footer() {
    return(
        <footer className="footer">
            <Link to="/privacy">Privacy Policy</Link>
            <Link to="/contact">Contact</Link>
        </footer>
    );
}
