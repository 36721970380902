import './ComingSoon.css';
import Logo from '../../Assets/Images/Logos/LexCraft.svg';

export default ComingSoon =>
<div className="coming-soon">
  <div>
    <img width="150"
         height="150"
         alt="LexCraft Logo"
         src={Logo} />
  </div>
  <h1>LexCraft</h1>
  <h2>Coming Soon</h2>
</div>;
